import React, { Component } from 'react';
import PropTypes from "prop-types";

class MosaicFeatures extends Component {
  render() {
      //Feature loop start
      const featuredata = this.props.featuresData.map((feature, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <i className={feature.icon} />
                </div>
                <h3>{feature.heading}</h3>
                <p>{feature.description}</p>
            </div>
        </div>
    ));
    //Feature loop END
    return (
        <>
            <section id="mosaicfeatures" className="mosaicfeatures ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {featuredata}
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
MosaicFeatures.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
MosaicFeatures.defaultProps = {
    SectionbgTitle: "Data Hub",
    sectionTitle: "Data Hub",
    sectionDescription: "MOSAIC\u2122 is a master data management hub utilizing keyless, advanced analytics, to harmonize your data to multiple global and national standards.  MOSAIC then returns to you aligned and enriched catalogs to enable data sharing.",
    featuresData: [
      {
          heading: "Keyless Integration",
          description:
          "Ingest your master data using your own native formats."
      },
      {
          heading: "Global Standards",
          description:
          "Quickly standardize your data, including Products and Locations."
      },
      {
          heading: "Manage Quality",
          description:
          "Beautiful user interface to customize your data alignments."
      },
      {
          heading: "Powered by AI/ML",
          description:
          "MOSAIC learns and improves the more it is used."
      },
      {
          heading: "Rapid Insight",
          description:
          "Compare your master data to other sources and rapidly identify outliers."
      },
      {
          heading: "Collaborate",
          description:
          "Share your master data alignment with other MOSAIC users."
      },
      {
          heading: "Visualize",
          description:
          "Build dashboards and analyses with your data, directly in MOSAIC."
      },
      {
          heading: "Batch and API",
          description:
          "Embed robust master data management into your workflows using MOSAIC APIs."
      },
      {
          heading: "Simple",
          description:
          "Simplified pricing structure; contact us for more information."
      },
    ]
};

export default MosaicFeatures;
