import React, { Component } from 'react';
import PropTypes from "prop-types";

class MosaicProducts extends Component {
  render() {
    //Service loop END
    return (
        <>
            <section id="mosaicproducts" className="mosaicproducts ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12"  >
                              <img src={require("../assets/img/it2/mosaic-products-1200x489.png")} alt="mosaic-products" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
MosaicProducts.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string
};

MosaicProducts.defaultProps = {
    SectionbgTitle: "Products",
    sectionTitle: "Products",
    sectionDescription: "Powerful tools to identify outliers and help you quickly standardize your Products."
}

export default MosaicProducts;
