import React, { Component } from 'react';

// Import Component
import NavBar from "../components/NavBar";
import BannerOne from "../components/banner/BannerOne";
import Services from "../components/Services";
import About from "../components/About";
import Mosaic from "../components/Mosaic";
import Footer from "../components/Footer";

class HomeOne extends Component {
  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar pageName="home" />

            {/* BannerOne: src/components/banner/BannerOne */}
            <BannerOne />

            {/* Services: src/components/Services.jsx */}
            <Services />

            {/* Mosaic: src/components/Mosaic.jsx */}
            <Mosaic />

            {/* About: src/components/About.jsx */}
            <About />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}

export default HomeOne;

// {/* WelcomeServices: src/components/WelcomeServices.jsx */}
// <WelcomeServices />
//
// {/* Team: src/components/Team.jsx */}
// // <Team />
//
// {/* Blog: src/components/Blog.jsx */}
// // <Blog />
//
// {/* VideoArea: src/components/VideoArea.jsx */}
// // <VideoArea />
//
// {/* Pricing: src/components/Pricing.jsx */}
// // <Pricing />
//
// {/* FunFacts: src/components/FunFacts.jsx */}
// // <FunFacts />
//
// {/* Testimonials: src/components/Testimonials.jsx */}
// // <Testimonials />
//
// {/* FAQ: src/components/FAQ.jsx */}
// // <FAQ />
//
// {/* Partner: src/components/Partner.jsx */}
// // <Partner />

// {/* Contact: src/components/Contact.jsx */}
// <Contact />
