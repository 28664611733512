import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import slideBgImg from '../../assets/img/it2/keyboard-analytics-cropped.png';

const bannerData = [
    {
        id: "1",
        SlideBgImg: `${slideBgImg}`,
        TopTitle: "",
        Title: "Data Innovators & Serverless Experts",
        Content:
            "We offer consulting and managed services to support your cloud journey, from inception through to globally scalable and secure production operations.",
        BtnLink:  "#contact",
        BtnName: "get in touch"
    }
];

export default function BannerOne() {
  return (
    <>
      <Swiper
        id="home"
        navigation={true}
        modules={[Navigation]}
        className="homepage-slides"
      >
        {bannerData && bannerData.map((banner) => (
            <SwiperSlide key={banner.id}>
                <div
                    className="single-slider-item"
                    style={{ backgroundImage: `url(${banner.SlideBgImg})` }}
                >
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <span className="hero-text">
                                            {banner.TopTitle}
                                        </span>
                                        <h1>{banner.Title}</h1>
                                        <p>{banner.Content}</p>
{/*
                                        <div className="center-wrap">
                                            <div className="button">
                                                <a href={banner.BtnLink}>{banner.BtnName} <i className="icofont-long-arrow-right"></i></a>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
