import React, { Component } from 'react';

// Import Component
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MosaicProducts from "../components/MosaicProducts";
import MosaicLocations from "../components/MosaicLocations";
import MosaicFeatures from "../components/MosaicFeatures";

class Mosaic extends Component {
    render() {
          return (
              <>
                  {/* NavBar: src/components/NavBar.jsx */}
                  <NavBar pageName="mosaic" />
                
                  {/* MosaicSolution: src/components/MosaicSolution */}
                  <MosaicFeatures />

                  {/* MosaicProducts: src/components/MosaicProducts */}
                  <MosaicProducts />

                  {/* MosaicLocations: src/components/MosaicLocations */}
                  <MosaicLocations />

                  {/* Footer: src/components/Footer.jsx */}
                  <Footer />
              </>
          );
        }
      }


export default Mosaic;
