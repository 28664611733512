import React, { Component } from 'react';
import PropTypes from "prop-types";

class MosaicLocations extends Component {
  render() {
    //Service loop END
    return (
        <>
            <section id="mosaiclocations" className="mosaiclocations ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12"  >
                              <img src={require("../assets/img/it2/mosaic-locations-1200.png")} alt="mosaic-facilities" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
MosaicLocations.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string
};

MosaicLocations.defaultProps = {
    SectionbgTitle: "Locations",
    sectionTitle: "Locations",
    sectionDescription: "Find facilities and service delivery points, and harmonize your locations."
}

export default MosaicLocations;
