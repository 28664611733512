import React, { Component } from 'react';
import PropTypes from "prop-types";

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <i className={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "",
    servicesData: [
        {
            icon: "icofont-automation",
            heading: "AWS Secure Cloud Computing",
            description:
                "We help organizations of all sizes and industries navigate the complexities of cloud computing, with a focus on security and compliance. From infrastructure design and deployment to ongoing management and optimization, we have the skills and experience to support your cloud journey."
        },
        {
            icon: "icofont-chart-growth",
            heading: "Data Analytics",
            description:
                "We help organizations make sense of their data and turn it into actionable insights. Our services include data warehousing, ETL, data modeling, and visualization.We recommend using AWS-native tools and technologies where appropriate, snd when your needs exceed what AWS can do, we recommend supplementing with proven technologies which can integrate seamlessly with AWS."
        },
        {
            icon: "icofont-globe",
            heading: "International Development and Global Health",
            description:
                "We have a deep understanding of the unique challenges faced by organizations working in these sectors. We help NGOs, government agencies, and other organizations use technology to achieve their development and health goals. We pride ourselves on our ability to work closely with our clients to understand their needs and deliver tailored solutions."
        }
    ]
};

export default Services;
