import React, { Component } from "react";
import PropTypes from "prop-types";

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <p className="copyright">{this.props.copyrightText}</p>
                            </div>
                            <div className="col-md-7">
                                <div className="social-icons bottom">
                                    <ul className="list-inline">
                                        <i className="icofont-email"/>
                                        <li>info@it2solutions.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}
//Props Types
Footer.propTypes = {
    copyrightText: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
    copyrightText: "© 2023 IT2 Solutions, Inc.",
};
export default Footer;
