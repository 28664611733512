import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

class Mosaic extends Component {
    render() {
        return (
            <>
                <section id="mosaic" className="mosaic cta-area cta-bg-img">
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <div className="row">
                                        <div className="col-sm-6 col-md-7 col-lg-7">
                                            <div className="cta-info">
                                                <h2>{this.props.Title}</h2>
                                                <p>{this.props.Content}</p>

                                                <div className="center-wrap">
                                                    <Link to={this.props.BtnLink} className="btn-a">
                                                        <div className="button">
                                                            {this.props.BtnName}
                                                            <i className="icofont-long-arrow-right" />
                                                        <div className="mask" /></div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5 cta-img">
                                            <img className="img-fluid" src={this.props.Image} alt="mac-pro" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
Mosaic.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
    Image: PropTypes.string,

};

//Default Props
Mosaic.defaultProps = {
    Title: "MOSAIC\u2122: Rapid Data Harmonization and Enrichment",
    Content: "A master data management hub to harmonize and enrich your data with multiple global and national standards.",
    BtnLink: "/mosaic",
    BtnName: "LEARN MORE",
    Image: require("../assets/img/it2/mosaic-screen-align-product.png"),
};

export default Mosaic;
